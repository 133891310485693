@import './../../../theme/theme';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.projectEditBlock {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: $backgroundColor;
  border-radius: 50%;
  font-size: $fontXSmall;
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  z-index: 9;
  &:hover {
    cursor: pointer;
  }
}

.singleProject {
  border: solid #FFF;
  border-width: 3px;
  border-radius: 10px;
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.projectImage {
  height: 160px;
  width: 100%;
  background-color: $primaryColor;
  position: relative;
  transition: all 1s ease;
  border-radius:7.2px 7.2px 0 0;
  > .projectImageBlock {
    transition: all 1s ease;
    left: 16px;
  }

  &.projectCollapsed {
    height: 100px;
    transition: all 1s ease;
    // > .projectImageBlock {
    //   left: 50px;
    // }
  }
}

@keyframes collapseImage {
  0% {
    height: 160px;
  }
  100% {
    height: 50px;
  }
}

@keyframes expandImage {
  0% {
    height: 50px;
  }
  100% {
    height: 160px;
  }
}

@keyframes translateLeft {
  0% {
    left: 16px;
  }
  100% {
    left: 50px;
  }
}

@keyframes translateRight {
  0% {
    left: 50px;
  }
  100% {
    left: 16px;
  }
}

.projectImage:hover {
  cursor: pointer;
}

.projectImageFile {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7.2px 7.2px 0 0;
}

.projectImageBlock {
  position: absolute;
  bottom: 12px;
  left: 7px;
}

.projectType,
.projectEcosystem {
  font-family: $primaryFontFamily;
  font-size: $fontXXSmall;
  color: $light;
  font-weight: bold;
  text-transform: uppercase;
}

.projectType {
  line-height: 0.9em;
}

.projectName {
  font-family: $primaryFontFamily;
  font-size: $fontMedium;
  color: $light;
  padding-right: 16px;
}

.verifiedIcon {
  display: inline-block;
  vertical-align: top;
  width: 0.95em;
  min-width: 0.95em;
  line-height: 0.95em;
}

.topProjectBadge {
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: var(--top-project-background-color);
  border-radius: 14px;
  padding: 2px 8px 2px 2px;
  font-weight: 700;
  font-size: 12px;

  * {
    color: #6D4230;
  }

  .badgeIcon {
    background-color: white;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 14px;
    }
  }
}

/* Progress Bar */
.progressBar {
  height: 4px;
  width: 100%;
  background-color: $dividerColor;
}

.progressBarHighlight {
  height: 4px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.projectInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
}

.targetLocation {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.target {
  font-family: $primaryFontFamily;
  font-size: 13px;
  color: $primaryFontColor;
  font-weight: bold;
  margin-right: 4px;
}

.projectTPOName {
  cursor: pointer;
  position: relative;
  text-align: center;
  color: #4F4F4F;
  height: 34px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 10px;
  padding: 9px 0;
  border-bottom-left-radius: 7.2px;
  border-bottom-right-radius: 7.2px;
}

.projectData {
  max-width: 178px;
}

.projectCost {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.donateButton {
  min-width: 75px;
  max-width: 150px;
  padding: 0 4px;
  height: 22px;
  display: flex;
  justify-content: center;
  border-radius: 11px;
  color: $light;
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.52em;
}

.donateButton:hover {
  cursor: pointer;
  text-decoration: none;
}

.perUnitCost {
  font-family: $secondaryFontFamily;
  font-size: $fontXSmall;
  color: $primaryFontColor;
  margin-top: 6px;
  font-weight: 700;
}

.perUnitCost > span {
  font-family: $primaryFontFamily;
  font-weight: 400;
}

.topProjectReportsContainer {
  margin: 10px;
}

.topProjectPopupContainer {
  margin: 10px;
  width: 300px;
}
.projectTypeIcon {
  display: inline-block;
  width: 0.95em;
  min-width: 0.95em;
  margin-right: 4px;
  font-size: var(--font-xx-extra-small);
  line-height: 0.3em;
}

.topApproved {
  background: var(--top-project-background-color);
}

.topUnapproved {
  background-color: #219653;
}

.notDonatable {
  background-color: var(--non-donatable-project-background-color);
}
.projectHoverIcon {
  display: inline-flex;
  font-size: 10px;
  font-weight: 700;
  color: var(--non-donatable-project-background-color);
  align-items: center;
 gap: 4px;
 line-height: normal;
}

.projectInfoPopupContainer{
  margin: 10px;
  max-width: 250px;
}

.projectEcosystemOrTypeContainer {
  display: flex;
  align-items:center;
}